<template>
<div id="companyDetail">
  <div class="c-info">
    <div class="c-info-wrap">
      <div class="info-primary">
        <img class="enterpriseLogo" :src="companyInfo.enterpriseLogo" />
        <p>
          <span>
            <img v-show="companyInfo.status==0" class="blueV" src="@/assets/imgs/company/blueV.png" />
            {{companyInfo.enterpriseName}}
            <i v-show="companyInfo.collectionCompany==0" @click="collectionCompany" class="el-icon-star-off"></i>
            <i v-show="companyInfo.collectionCompany==1" @click="cancelFavorite" class="el-icon-star-off star-on"></i>
          </span>
          <label>{{companyInfo.enterpriseNatureName}} <em v-show="companyInfo.scaleName">|</em> {{companyInfo.scaleName}} <em v-show="companyInfo.industryName">|</em> {{companyInfo.industryName}}</label>
        </p>
      </div>
      <div class="c-stat">
        <span>{{total}}</span>
        <label>在招职位</label>
      </div>
    </div>
  </div>
  <div class="detail-wrap">
    <div class="company-tab">
      <ul>
        <li @click="togSub(1)"  :class="{curCompany:subC==1}">公司简介<span v-show="subC==1"></span></li>
        <li @click="togSub(2)" :class="{curCompany:subC==2}">在招职位({{total}})<span v-show="subC==2"></span></li>
      </ul>
    </div>
    <div class="company-profile" v-show="subC==1">
      <div class="company-sec">
        <h3>公司简介</h3>
        <p v-if="companyInfo.detailAddress">{{companyInfo.profile}}</p>
        <p v-else>暂无公司简介</p>
      </div>

      <div class="company-address" v-show="companyInfo.detailAddress">
        <h3>公司地址</h3>
        <img src="@/assets/imgs/company/location-blue.png"><span>{{companyInfo.detailAddress}}</span>
         <div class="companyMap" v-show="showMap">
          <el-amap ref="map" :events="events" zoom="18" :center="center"  class="amap-demo">
            <el-amap-marker key="100" :position="center"></el-amap-marker>
          </el-amap>
        </div>
      </div>
      <div class="company-photo" v-show="photoAlbum.length>0">
        <h3>公司相册</h3>
        <div class="cp-list">
          <div class="cp-pic" v-for="(pic,index) in photoAlbum" :key="index">
            <img :src="pic" />
          </div>
        </div>
      </div>
    </div>
    <div class="job-box" v-show="subC==2">
      <div class="job-list" v-show="jobList.length>0">
        <div class="job-item" v-for="(item,index) in jobList" :key="index" @click="jumpJob(item)">
          <div class="job-info-left">
            <h3>{{item.positionName}}</h3>
            <p>
              <img v-show="item.areaName" style="width:14px" src="@/assets/imgs/location-icon.png" /><span v-show="item.areaName">{{item.areaName}}</span>
              <img v-show="item.minimumEducationName" src="@/assets/imgs/edu-icon.png" /><span>{{item.minimumEducationName}}</span>
              <img v-show="item.workExperienceName" src="@/assets/imgs/time-icon.png" /><span>{{item.workExperienceName}}</span>
            </p>
          </div>
          <div class="job-info-right">
            <h3>
              <em>
                {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
              </em>
            </h3>
            <p>{{item.createTime}}</p>
          </div>
        </div>
      </div>
      <el-empty v-show="jobList.length==0" :image-size="200" description="暂无在招职位"></el-empty>
    </div>
    <div class="company-sider">
      <div class="benefits">
        <h3>公司福利</h3>
        <ul>
          <li v-for="(item,index) in benefitsMap" :key="index">{{item}}</li>
        </ul>
      </div>
      <div class="benefits business">
        <h3>工商信息</h3>
        <p>
          <span>企业类型：</span>
          <label>{{companyInfo.compositionForm}}</label>
        </p>
        <p>
          <span>统一社会信用代码：</span>
          <label>{{companyInfo.socialCreditCode}}</label>
        </p>
        <p>
          <span>成立日期：</span>
          <label>{{companyInfo.establishedTime}}</label>
        </p>
        <p>
          <span>注册资本：</span>
          <label>{{companyInfo.registeredCapital}}</label>
        </p>
        <div class="source">
          <span>数据来源：</span>
          <img @click="openQcc" src="@/assets/imgs/tianyancha.png" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getCompanyDetail, getJobList,collectionCompany,cancelFavorite } from "@/api/index.js";
export default {
  name: "companyDetail",
  data() {
    let _this = this;
    return {
      subC: '1',
      companyInfo: {},
      jobList: [],
      photoAlbum: [],
      benefitsMap: [],
      total: 0,
      center: [93.51452,42.82699],
      events: {
        init(){
         _this.getCompanyDetail()
        }
      },
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false,
      },
      showMap:false
    }
  },
  mounted(){
    this.getJobList()
  },
  methods: {
    collectionCompany(){
      collectionCompany({
         enterpriseId:this.companyInfo.enterpriseId
      }).then(res=>{
         if(res.code==200){
           this.$message({
            message: '关注成功',
            type: 'success'
           })
          this.getCompanyDetail()
        }
      })
    },
    cancelFavorite(){
       cancelFavorite(this.companyInfo.collectId).then(res=>{
        if(res.code==200){
           this.$message({
            message: '取消关注成功',
            type: 'success'
           })
           this.getCompanyDetail()
        }
      })
    },
    openQcc(){
      window.open('https://www.tianyancha.com/search?key='+this.companyInfo.enterpriseName)
    },
    jumpJob(item) {
      this.$router.push({
        path: '/jobDetail',
        query: {
          positionId: item.id
        }
      })
    },
    getCompanyDetail() {
      getCompanyDetail({ enterpriseId: this.$route.query.enterpriseId }).then(res => {
        this.companyInfo = res.data
        if(this.companyInfo.enterpriseMapLongitude){
           this.showMap = true
           this.center= [this.companyInfo.enterpriseMapLongitude, this.companyInfo.enterpriseMapLatitude];
        }
       
        console.log(this.center,'sssssss')
        if(res.data.photoAlbum){
           this.photoAlbum = res.data.photoAlbum.split(',') 
        }
        this.benefitsMap = []
        var materialBenefits = JSON.parse(localStorage.getItem('benefitsList'))
        var benefits = res.data.materialBenefits.split(',')
        benefits.forEach(g => {
          materialBenefits.forEach(s => {
            if (g == s.dictValue) {
              this.benefitsMap.push(s.dictLabel)
            }
          })
        })
      })
    },
    getJobList() {
      getJobList({ enterpriseId: this.$route.query.enterpriseId }).then(res => {
        this.jobList = res.rows
        this.total = res.total
      })
    },
    togSub(index) {
      this.subC = index
    }
  }
};
</script>

<style lang="scss" scoped>
#companyDetail {
  width: 100%;
  min-height: 400px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-bottom: 40px;
}
.companyMap{
  padding:15px 0;
  overflow: hidden;
  .amap-demo{
    height: 250px;
  }
  .el-vue-search-box-container {
   width: 100%;
   margin-left: 0;
   margin-bottom: 10px;
   border-top: 1px solid #eee;
  }
}
.detail-wrap {
  width: 1200px;
  margin: 0 auto;
}

.noData {
  font-size: 30px;
  color: #222;
  letter-spacing: 1px;
  margin: 140px 240px;
}

.c-info {
  height: 175px;
  background-image: linear-gradient(90deg,#3b526a 0,#345a6d 100%);
}
.c-info-wrap{
  width: 1200px;
  margin: 0 auto;
}
.info-primary {
  height: 100px;
  float: left;

  .enterpriseLogo {
    width: 75px;
    height: 75px;
    display: block;
    margin:48px 20px;
    border-radius: 12px;
    float: left;
  }
  .blueV{
    width: 20px;
    margin-right: 10px;
    float: left;
  }
  em{
    margin: 0 10px;
    color: rgb(223, 221, 221);
  }
  i{
    color: #fff;
    margin-left: 10px;
    font-size:23px;
    cursor: pointer;
  }
  .star-on{
    color: #0077FF;
  }
  p {
    float: left;
    margin-top:50px;

    span {
      font-size: 27px;
      font-weight: 600;
      color: #fff;
      line-height: 40px;
    }

    label {
      font-size: 13px;
      color: #fff;
      display: block;
      margin-top:9px;
    }
  }
}

.c-stat {
  float: right;
  width: 85px;
  height: 85px;
  background: #D9EAFE;
  margin-top: 45px;
  text-align: center;
  border-radius: 5px;

  span {
    display: block;
    font-size: 29px;
    font-weight: bold;
    color: #0078FF;
    margin-top: 15px;
  }

  label {
    display: inline-block;
    margin-top: 5px;
    font-size: 15px;
    color: #0078FF;
  }
}

.company-tab {
  background: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 12px;
  width: 1150px;
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;

  ul {
    li {
      float: left;
      height:49px;
      cursor: pointer;
      margin-right: 58px;
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      position: relative;
    }
    .curCompany{
      color: #0077FF;
    }
    span{
      display: inline-block;
      width: 35px;
      height: 3px;
      background: #0077FF;
      position: absolute;
      left: 22px;
      bottom: 0;
      border-radius: 10px;
    }
  }

}

.company-profile {
  width: 790px;
  min-height:170px;
  float: left;
  background: #FFFFFF;
  border-radius: 10px;
  margin-right: 10px;
  padding: 20px 10px 20px 20px;
  overflow: hidden;

  .company-sec {
    h3 {
      font-size: 18px;
      color: #222222;
    }

    p {
      text-indent: 20px;
      line-height: 30px;
      font-size: 16px;
      color: #222222;
      margin-top: 16px;
    }
  }

  .company-address {
    h3 {
      font-size: 18px;
      color: #222222;
      margin-top: 22px;
      margin-bottom: 14px;
    }

    img {
      width: 16px;
      height: 20px;
      position: relative;
      top: 3px;
    }

    span {
      display: inline-block;
      font-size: 16px;
      color: #222222;
      margin-left: 10px;
    }
  }

  .company-photo {
    h3 {
      font-size: 18px;
      color: #222222;
      margin-top: 25px;
      margin-bottom: 19px;
    }

    .cp-pic img {
      width: 239px;
      height: 144px;
      border-radius: 10px;
      float: left;
      margin-right: 11px;
      margin-bottom: 10px;
    }
  }
}

.job-box {
  float: left;
  width:820px;
  min-height: 100px;
  margin-right: 10px;

  .job-category {
    height: 40px;

    .job-category-label {
      color: #00a6a7;
      display: block;
      margin-right: 10px;
      float: left;
    }

    ul {
      float: left;

      li {
        float: left;
        margin-right: 10px;
      }
    }
  }

  .job-list {
    background: #FFFFFF;
    border-radius: 10px;

    .job-item {
      min-height: 62px;
      padding: 14px 20px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }

    .job-info-left {
      float: left;

      h3 {
        font-size: 18px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 10px;
      }

      img {
        position: relative;
        top: 3px;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        color: #999999;
        display: inline-block;
        margin-right: 60px;
      }
    }

    .job-info-right {
      float: right;
      text-align: right;

      em {
        font-style: normal;
        font-size: 18px;
        color: #FF2400;
        font-weight: 400;
      }

      p {
        font-size: 14px;
        color: #999999;
        margin-top: 16px;
      }
    }
  }
}

.company-sider {
  float: left;
  min-height: 500px;

  .benefits {
    width: 365px;
    min-height: 72px;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;

    h3 {
      background: linear-gradient(90deg,#f5fcfc 0,#fcfbfa 100%);
      border-radius: 12px 12px 0 0;
      font-size: 16px;
      font-weight: 500;
      color: #222;
      line-height: 22px;
      padding: 12px 24px;
    }
    ul{
      margin-left: 15px;
      padding-bottom: 20px;
      overflow: hidden;
    }
    li {
      float: left;
      background: #f8f8f8;
      border-radius: 4px;
      margin-left: 4px;
      margin-right: 4px;
      margin-top: 12px;
      padding: 4px 15px;
      font-size: 14px;
      color: #666;
      line-height: 20px;
      max-width: 100%;
      width:100px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }

  .business {
    margin-top: 10px;

    p {
      margin-top: 14px;
      margin-left: 20px;

      span {
        display: block;
        margin-bottom: 6px;
        font-size: 14px;
        color: #999999;
      }

      label {
        font-size: 14px;
        color: #222222;
      }
    }

    .source {
      border-top: 1px solid #eee;
      cursor: pointer;
      overflow: hidden;
      height:40px;
      margin:10px 20px;

      img {
        width: 60px;
        height: 38px;
        float: right;
        position: relative;
        top: 7px;
      }

      span {
        position: relative;
        top: 15px;
         font-size: 14px;
      }
    }
  }

}

</style><style lang="scss">
#companyInfo {
  .filter-box {
    clear: both;

    .el-select {
      margin-right: 15px;
    }

    .el-input__inner {
      width: 120px;
      height: 30px;
      line-height: 30px;
    }

    .el-input__icon {
      line-height: 30px;
    }
  }
}
</style>
